@import url('../../../src/styles/tailwind.scss');

$primary-color: #5e0756;
$second-color: #b30c5e;
$third-color: #166096;

$rgb-primary-color: 94, 7, 86; //--primary-color *
$rgb-second-color: 179, 12, 94; //--second-color *
$rgb-third-color: 22, 96, 150; //--third-color *

@import "../../../src/styles/vars.scss";
@import "../../../src/styles/styles.scss";

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&family=Rethink+Sans:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');

body.webapp-site {
	--font-title: 'Rethink Sans', sans-serif;
    --font-subtext: 'Open Sans', sans-serif;
    --font-text: 'Open Sans', sans-serif;
	background-color: #f6f9f9;

    .img__bg-nf {
        background-image: url('/assets/img/figure.svg');
    }
    @media screen and (max-width: 767px) {
        .img__bg-nf {
            background-image: url('/assets/img/figure-resp.svg');
        }
    }
}
